@import '~react-image-gallery/styles/css/image-gallery.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* --main-bg-color: #e8eae7; */
  --main-bg-color: #f1eeeb;
  --main-navbar-margin: 5rem;
}

p {
  margin: 0;
  line-height: 1.6rem;
}

.carousel.carousel-slider {
  overflow: visible !important;
  padding: 1rem;
}

.carousel .control-dots {
  width: calc(100% - 2rem) !important;
}

h2,
h3,
h4,
p {
  font-weight: 200;
}
